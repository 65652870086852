import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Text, Spinner, Center } from "@chakra-ui/react";
import CampaignPosts from "./campaigns/CampaignPosts";
import Header from "./campaigns/Header";
import Overview from "./campaigns/Overview";
// import Data from "./campaigns/Data";

const Campaigns = () => {
  const { shareId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          "https://hoobe-server-v5.onrender.com/campaigns-share-url",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ shareId }),
          }
        );
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (shareId) fetchData();
  }, [shareId]);

  return (
    <Box>
        {loading ? (
          <Center h="200px">
            <Spinner size="xl" />
          </Center>
        ) : error ? (
          <Text color="red.500">Error: {error}</Text>
        ) : data ? (
          <>
            <Header data={data.campaignDetails} />
            <Overview data={data.campaignDetails} />
            <CampaignPosts data={data} />
            {/* <Data data={data} /> */}
          </>
        ) : (
          <Text>No data available</Text>
        )}
    </Box>
  );
};

export default Campaigns;
